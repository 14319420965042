<template>
  <div class="container">
    <SfHeading
        title="Privacy Policy"
        :level="3"
        class="sf-heading--left sf-heading--no-underline title"
        style="margin-top:4rem"
    />
    <div class="markdown-block">
      <vue-simple-markdown :source="store.privacyPolicy"></vue-simple-markdown>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SfHeading} from "@lib/components"
import {VueSimpleMarkdown} from "vue-simple-markdown";
import "vue-simple-markdown/dist/vue-simple-markdown.css";

export default {
  components: {
    SfHeading,
    VueSimpleMarkdown,
  },
  computed: {
    ...mapGetters({
      store: "store",
    }),
  },
  created() {
    if (!this.store.privacyPolicy) {
      this.$router.replace("home")
    }
  }
};
</script>

<style lang="scss">
.markdown-block {
  padding: 2rem 1rem
}
</style>