<template>
  <div class="container">
    <SfHeading
        title="Terms and conditions"
        :level="3"
        class="sf-heading--left sf-heading--no-underline title"
        style="margin-top:4rem"
    />
    <div class="markdown-block">
      <vue-simple-markdown :source="store.tAndC"></vue-simple-markdown>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SfHeading} from "@lib/components"
import {VueSimpleMarkdown} from "vue-simple-markdown";
import "vue-simple-markdown/dist/vue-simple-markdown.css";


export default {
  components: {
    SfHeading,
    VueSimpleMarkdown
  },
  computed: {
    ...mapGetters({
      store: "store",
    }),
  },
  created() {
    if (!this.store.tAndC) {
      this.$router.replace("home")
    }
  }
};
</script>
